<template>
  <div>

    <b-form @submit.stop.prevent="formOnsubmit">

      <!-- Name input -->
      <b-form-group
        id="input-group-name"
        label="Nama:"
        label-for="input-name"
      >
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Nama COA"
        ></b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <!-- Number input -->
      <b-form-group
        id="input-group-number"
        label="Number:"
        label-for="input-number"
      >
        <b-form-input
          id="input-number"
          v-model="form.number"
          placeholder="Number COA"
        ></b-form-input>
        <small class="text-danger">{{ error.number }}</small>
      </b-form-group>

      <!-- Input Coa Category -->
      <b-form-group
        id="input-group-coa-categories"
        label="Kategori COA:"
        label-for="input-coa-categories"
      >
        <treeselect
          v-model="form.coa_category_id"
          :multiple="false"
          :options="coa_categories"
        />
        <small class="text-danger">{{ error.coa_category_id }}</small>
      </b-form-group>

      <!-- Input Sub Ledger -->
      <b-form-group
        id="input-group-sub-ledgers"
        label="Sub Ledger:"
        label-for="input-sub-ledgers"
      >
        <treeselect
          v-model="form.sub_ledger_id"
          :multiple="false"
          :options="sub_ledgers"
        />
        <small class="text-danger">{{ error.sub_ledger_id }}</small>
      </b-form-group>

      <!-- Input Cash Flow -->
      <b-form-group
        id="input-group-cash-flows"
        label="Cash Flow:"
        label-for="input-cash-flows"
      >
        <treeselect
          v-model="form.cash_flow_id"
          :multiple="false"
          :options="cash_flows"
        />
        <small class="text-danger">{{ error.cash_flow_id }}</small>
      </b-form-group>

      <!-- Description input -->
      <b-form-group id="input-group-description">
        <label for="input-description">Deskripsi: <em class="text-muted">opsional</em></label>
        <b-form-textarea
          id="input-description"
          v-model="form.description"
          placeholder="Deksripsi COA"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <!-- Submit & Cancel button -->
      <b-button
        type="submit"
        variant="primary"
      >Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/coas')"
      >
        Batal
      </b-button>

    </b-form>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {

  props: {
    form: Object,
    route: String
  },

  data() {
    return {
      // Error
      error: {
        name: '',
        number: '',
        coa_category_id: '',
        sub_ledger_id: '',
        cash_flow_id: '',
        description: ''
      },
      coa_categories: [],
      sub_ledgers: [],
      cash_flows: [],
    }
  },

  methods: {

    async getCoaCategoryOption() {
      let response = await module.setTreeSelect("coa-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.coa_categories = response.data;
        this.coa_categories.unshift({
          label: "Pilih Kategori COA",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getSubLedgerOption() {
      let response = await module.setTreeSelect("sub-ledgers");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.sub_ledgers = response.data;
        this.sub_ledgers.unshift({
          label: "Pilih Sub Ledger",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getCashFlowOption() {
      let response = await module.setTreeSelect("cash-flows");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.cash_flows = response.data;
        this.cash_flows.unshift({
          label: "Pilih Cash Flow",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$router.push('/coas')
      }
    }

  },

  mounted() {
    this.getCoaCategoryOption()
    this.getSubLedgerOption()
    this.getCashFlowOption()
  }

}
</script>

<style>
</style>